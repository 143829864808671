@import '../../styles/settings.scss';

.skills__wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  overflow: hidden;
  padding: 6rem 0;
}

.skills__title {
  padding: 0 10%;

  p {
    text-transform: uppercase;
    font-family: $font-head;
    font-size: 3rem;
  }
}

.skills__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skills__box {
  border-top: 1px solid $eerie-black;
  height: 4.5rem;
  display: flex;
  align-items: center;
  gap: 6rem;
  padding: .5rem 10%;
  text-transform: uppercase;
  cursor: crosshair;

  &:last-of-type {
    border-bottom: 1px solid $eerie-black;
  }

  img {
    transition: all .2s ease-in-out;
    width: 3rem;
    height: 3rem;
  }

  p {
    transition: all .3s ease-in-out;
    font-size: 1.2rem;
    color: $eerie-black;
  }

  &:hover {
    p {
      letter-spacing: .25rem;
      color: $jasper;
    }

    img {
      filter: invert(30%) sepia(77%) saturate(728%) hue-rotate(325deg) brightness(95%) contrast(85%);
    }

    .skill__hover {
      opacity: 1;
    }
  }
}

.skill__hover {
  opacity: 0;
}

@media (max-width: 540px) {

  .skills__wrapper {
    align-items: center;
    padding: 3rem 0;
  }

  .skills__title {
    p {
      font-size: 2.1rem;
    }
  }

  .skills__box {
    gap: 3rem;
    height: 3rem;

    img {
      width: 2rem;
      height: 2rem;
    }

    p {
      font-size: .9rem;
    }
  }
}
