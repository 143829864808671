//  FONTS
$font-head: 'Oswald', sans-serif;
$font-text: 'Quicksand', sans-serif;

//  PALLETTE
$jasper: rgba(182, 76, 57, 1);
$cosmic-latte: rgba(245, 242, 237, 1);
$eerie-black: rgba(37, 34, 32, 1);
$raven: rgba(7, 17, 8, 1);
$white: rgb(255, 250, 245, 1);
