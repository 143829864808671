@import '../../styles/settings.scss';

.e404__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 5%;
  padding-top: 9rem;
  text-align: center;

  h1 {
    font-family: $font-head;
    color: $jasper;
    font-size: 6rem;
    font-weight: 400;
  }

  p {
    font-size: 1.2rem;
  }

  span {
    color: $jasper;
  }

  a {
    color: $eerie-black;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: crosshair;
  }
}
