@import '../../styles/settings.scss';

.projects__wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 10%;
}

.projects__container {
  //overflow: hidden;
  display: flex;
  gap: 1rem;
}

.projects__col {
  height: 85vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
}

//  BOXES
.projects__box {
  background-color: $cosmic-latte;
  border: 1px solid $eerie-black;
  overflow: hidden;
  cursor: crosshair;
  padding-top: 1rem;
  position: relative;
  background-color: $white;

  &:hover {

    /*
    .projects__box__mockup {
      transform: translateY(-100%);
      visibility: hidden;
    }
    */

    .projects__box__overlay {
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.medium {
  height: 40%;
  width: 100%;
}

.big {
  height: 60%;
  width: 100%;
}

.small {
  height: 100%;
}

.projects__box__mockup {
  transition: all .8s ease-in-out;
  height: 100%;
  width: 100%;
  visibility: visible;
  transform: translateY(0);
}

.projects__box__overlay {
  transition: all .8s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateY(-100%);
  visibility: hidden;
}

@media (max-width: 540px) {
  .projects__wrapper {
    flex-direction: column-reverse;
    align-items: center;
    gap: 2rem;
    padding: 3rem 5%;
  }

  .projects__container {
    flex-direction: column;
  }

  .medium, .big {
    height: 100%;
  }
}
