@import './settings.scss';

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  user-select: none;
  cursor: default;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

//  SCROLLBAR
html::-webkit-scrollbar {
  display: none;
}

html::-webkit-scrollbar-thumb {
  background: $jasper;
}

html::-webkit-scrollbar-thumb:hover {
  background: $jasper;
}

//  BODY
body {
  padding: 0;
  margin: 0;
  background-color: $cosmic-latte;
  font-family: $font-text;
  color: $cosmic-latte;
}

span {
  color: $jasper;
}

h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  margin: 0;
  line-height: 1.6;
  color: $eerie-black;
}

a {
  text-decoration: none !important;

  &:link {
    text-decoration: none !important;
  }
}
