@import '../../styles/settings.scss';

#dropdown {
  display: none;
}

.navbar__top__dropdown__btn {
  display: none;
}

.navbar__top__wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navbar__top__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  padding: 0 10%;
  height: 3rem;
  background-color: $cosmic-latte;
}

.navbar__links {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 2rem;
  height: 1.5rem;
  overflow: hidden;

  button {
    background-color: transparent;
    border: none;
    font-family: $font-text;
    font-size: 1rem;
    cursor: crosshair;
  }
}

.navbar__links__el {
  display: flex;
  flex-direction: column;
  transition: all .2s ease-out;

  &:hover {
    transform: translateY(50%);

    button {
      color: $jasper;
    }
  }
}

.navbar__top__language__switch {
  width: 4.5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  padding: .1rem;
  cursor: crosshair;
}


.navbar__top__language__pl, .navbar__top__language__eng {
  width: 50%;
  text-align: center;
  color: $eerie-black;
  transition: all .2s ease-in-out;

  &:hover {
    color: $jasper;
  }
}

.clickedPl, .clickedEng {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $raven;
  height: 100%;
  color: $cosmic-latte;

  &:hover {
    color: $cosmic-latte;
  }
}

@media (max-width: 540px) {

  #dropdown {
    display: block;
  }

  .navbar__top__logo {
    display: none;
  }

  .navbar__top__dropdown__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .3rem;

    .dropdown__btn__inner {
      background-color: $eerie-black;
      width: 2rem;
      height: .25rem;
      transition: all .4s ease;
    }
  }

  .navbar__top__dropdown__btn.on {
    .firstHam {
      transform: rotate(45deg);
      background-color: $jasper;
    }

    .thirdHam {
      margin-top: -.55rem;
      transform: rotate(-45deg);
      background-color: $jasper;
    }
  }

  .dropdown__container {
    position: absolute;
    top: 2.5rem;
    left: 0;
    background-color: $cosmic-latte;
    height: 100vh;
    width: 18%;
    transform: translateX(-101%);
    transition: all .4s ease-in-out;
  }

  .dropdown__container.show {
    transform: translateX(0);
  }

  .dropdown__links {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    gap: 5.3rem;
    padding: 3.5rem 0;
    margin: 0;

    li button {
      width: 5rem;
      background-color: transparent;
      border: none;
      font-family: $font-head;
      color: $eerie-black;
      font-size: 1.5rem;
      transform: rotate(-90deg);
    }
  }

  .navbar__top__container {
    height: 2.5rem;
    justify-content: space-between;
  }

  .navbar__top__container {
    padding: 0 5%;
  }

  .navbar__links__container {
    display: none;
  }

  .navbar__top__language__switch {
    height: 1.6rem;
  }
}
