@import '../../styles/settings.scss';

.contact__wrapper {
  background-color: $raven;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 10%;
  margin-top: 6rem;
}

.contact__container {
  display: flex;
  gap: 10%;
}

.contact__image {
  width: 40%;
  background-color: $cosmic-latte;
  border: 1px solid $eerie-black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem;

  img {
    transition: all .4s ease-in-out;
    width: 100%;
    filter: saturate(0) sepia(.1) contrast(.9) brightness(.9);
    transform: scaleX(-1);

    &:hover {
      filter: saturate(0.4);
    }
  }
}

.contact__info {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact__desc {
  text-transform: uppercase;

  h1 {
    color: $cosmic-latte;
    font-family: $font-head;
    font-size: 3rem;
    font-weight: 400;
  }

  p {
    color: $cosmic-latte;
  }

  p:last-of-type {
    color: $jasper;
    margin-bottom: 1rem;
  }
}

.contact__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    padding: .5rem;
    background-color: transparent;
    border: 1px solid $cosmic-latte;
    font-family: $font-text;
    font-size: 1rem;
    color: $cosmic-latte;
    text-transform: uppercase;
    cursor: crosshair;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: $jasper;
      border: 1px solid $jasper;
      color: $eerie-black;
    }
  }
}

.contact__form__text, .contact__form__message {
  background: $cosmic-latte;
  color: $eerie-black;
  font-family: $font-text;
  font-size: 1rem;
  border: 1px solid $eerie-black;
  outline: none;
  padding: .5rem 1rem;

  &::placeholder {
    color: $eerie-black;
    opacity: .5;
  }
}

.contact__form__message {
  height: 10rem;
}

.contact__modal {
  position: absolute;
  width: 30%;
  background-color: $jasper;
  padding: .5rem 1rem;
  bottom: 10%;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%) translateY(400%);
  opacity: 0;
  transition: transform .5s ease-in-out;

  p {
    color: $eerie-black;
    text-align: center;
  }

  &.send {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@media (max-width: 540px) {
  .contact__wrapper {
    min-height: 100vh;
    padding: 3rem 5%;
    margin-top: 0;
  }

  .contact__container {
    flex-direction: column;
    gap: 1rem;
  }

  .contact__image {
    width: 100%;
    padding: 5rem 0;

    img {
      width: 65%;
      transform: rotate(-90deg);
    }
  }

  .contact__info {
    width: 100%;
  }

  .contact__desc {
    h1 {
      font-size: 2.4rem;
    }
  }

  .contact__form__text, .contact__form__message {
    font-size: .9rem;
  }

  .contact__form__message {
    height: 5rem;
  }

  .contact__modal {
    width: 90%;
    bottom: 5%;
    padding: .5rem;

    p {
      font-size: .8rem;
    }
  }
}
