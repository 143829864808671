@import '../../styles/settings.scss';

.footer__wrapper {
  display: flex;
  flex-direction: column;
  background-color: $raven;
  padding: 3rem 10%;
  padding-bottom: 1rem;
}

.footer__container {
  display: flex;
  align-items: flex-start;
}

.footer__start {
  width: 50%;
}

.footer__start__logo {
  width: 15rem;
  border-top: 3px solid $jasper;
  padding-top: 3.5rem;

  a {
    color: $cosmic-latte;
    cursor: crosshair;
    transition: all .2s ease-in-out;

    &:hover {
      color: $jasper;
    }
  }
}

.footer__end {
  width: 50%;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3rem;
}

.footer__end__links {
  display: flex;
  align-items: center;
  gap: 2rem;
  height: 3rem;
  list-style-type: none;
  margin: 0;

  button {
    background-color: transparent;
    border: none;
    font-family: $font-text;
    font-size: 1rem;
    color: $cosmic-latte;
    cursor: crosshair;
    transition: all .2s ease-in-out;

    &:hover {
      color: $jasper;
    }
  }
}

.footer__end__socials {
  display: flex;
  align-items: center;
  gap: 2rem;

  img {
    display: flex;
    width: 2.5rem;
    cursor: crosshair;
    transition: all .2s ease-in-out;
    filter: invert(1);

    &:hover {
      filter: invert(30%) sepia(77%) saturate(728%) hue-rotate(325deg) brightness(120%) contrast(85%);
    }
  }
}

.footer__copy {
  p {
    color: $cosmic-latte;
    opacity: .5;
  }
}

@media(max-width: 540px) {
  .footer__wrapper {
    padding: 1.5rem 5%;
  }

  .footer__container {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .footer__start {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .footer__start__logo {
    border-top: 2px solid $jasper;
    width: 75%;
    align-items: center;
    text-align: center;
  }

  .footer__end {
    width: 100%;
    padding: 0;
    align-items: center;
  }

  .footer__end__links {
    gap: 1rem;
    height: fit-content;
    padding: 0;
  }

  .footer__end__socials {
    gap: 1.5rem;
  }

  .footer__copy {
    text-align: center;
    padding-top: 3rem;

    p {
      font-size: .8rem;
    }
  }
}
