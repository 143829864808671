.mockup__wrapper {
  width: 100%;
  height: 100%;
}

.mockup__image {
  width: 100%;
  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    filter: saturate(.4) sepia(.1);
  }
}
