@import '../../styles/settings.scss';

.mockupoverlay__wrapper {
  width: 100%;
  height: 100%;
  background-color: $white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}

.mockupoverlay__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.mockupoverlay__title {
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  p {
    font-family: $font-head;
    font-size: 2rem;
    text-transform: uppercase;
  }

  p:last-of-type {
    font-size: 1rem;
    color: $jasper;
  }

}

.mockupoverlay__links {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  img {
    width: 3rem;
    filter: invert(30%) sepia(77%) saturate(728%) hue-rotate(325deg) brightness(95%) contrast(85%);
    transition: all .2s ease-in-out;

    &:hover {
      filter: invert(0);
    }
  }
}

.mockupoverlay__link__el {
  display: inline-flex;

}

.mockupoverlay__link__el a {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $eerie-black;
  font-weight: 700;
  cursor: crosshair;


  &:hover {
    img {
      transition: all .2s ease-in-out;
      filter: invert(0);
    }
  }
}

@media(max-width: 540px) {
  .mockupoverlay__container {
    gap: 1rem;
  }

  .mockupoverlay__title {
    p {
      font-size: 1.5rem;
    }
  }

  .mockupoverlay__desc {
    p {
      font-size: .8rem;
    }
  }

  .mockupoverlay__links {
    img {
      width: 2rem;
    }
  }

  .mockupoverlay__link__el {
    display: block;
  }
}
