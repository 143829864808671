@import '../../styles/settings.scss';

.enter__off {
  display: none;
}

.enter__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.enter__container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.enter__box {
  width: 100%;
  height: 100%;
  background-color: $cosmic-latte;
}

@media (max-width: 540px) {

}
