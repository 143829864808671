@import '../../styles/settings.scss';

.about__wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6rem;
  padding: 3rem 10%;
}

.about__info__container {
  width: 50%;
  z-index: 3;
}

.about__title {
  z-index: 1;

  h1 {
    font-family: $font-head;
    font-weight: 400;
    font-size: 4rem;
  }
}

.about__info {
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.2rem;
  }
}

.about__image {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.about__image__inner {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  p {
    width: 13rem;
    font-size: 1rem;
    line-height: 1.4;
    color: $jasper;
  }

  img {
    transition: all .4s ease-in-out;
    border: 1px solid $eerie-black;
    width: 100%;
    filter: brightness(2) saturate(0) sepia(.1);

    &:hover {
      filter: brightness(2) saturate(.3) sepia(.1);
    }
  }
}

@media (max-width: 540px) {

  .about__wrapper {
    padding: 2.5rem 5%;
    gap: 3rem;
  }

  .about__info__container {
    width: 100%;
  }

  .about__title {
    h1 {
      font-size: 2.5rem;
    }
  }

  .about__info {
    width: 80%;

    p {
      font-size: 1rem;
    }
  }

  .about__image__inner {
    align-items: flex-end;
    width: 100%;
    gap: .5rem;

    p {
      width: 10.5rem;
      font-size: .8rem;
    }
  }
}
